import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

const Pound: React.FC = () => (
  <svg
    className="w-9 h-9 sm:w-14 sm:h-14"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.54688 21H49.0014"
      stroke="#0000FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.5 8.75L31.5 47.25"
      stroke="#0000FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 8.75L17.5 47.25"
      stroke="#0000FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 35H46.4546"
      stroke="#0000FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

function getQueryFromTags(tags: string[]): string {
  return `?${tags.map(tag => `tag=${tag}`).join("&")}`
}

const ListItem: React.FC<{ onClick: () => void }> = ({ onClick, children }) => {
  return (
    <li
      className="p-4 hover:bg-bg-white-hover cursor-pointer"
      onClick={onClick}
    >
      {children}
    </li>
  )
}

const TagsFilterMenu: React.FC<{ tags: string[] }> = ({ tags }) => {
  const [isOpen, setIsOpen] = useState(false)

  const wrapperRef = React.useRef<HTMLUListElement>(null)

  useEffect(() => {
    document.addEventListener("mousedown", e => {
      if (!wrapperRef.current?.contains(e.target as any)) {
        setIsOpen(false)
      }
    })
  }, [wrapperRef])

  return (
    <div
      className="relative"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <div className="cursor-pointer">
        <Pound />
      </div>
      {isOpen && (
        <ul
          className="fixed sm:absolute w-screen sm:w-50 lc-border bg-white sm:top-0 left-0 sm:left-auto sm:-right-2 z-10"
          ref={wrapperRef}
        >
          <ListItem
            onClick={() => {
              navigate("/blog")
              setIsOpen(false)
            }}
          >
            All posts
          </ListItem>
          {tags.map(tag => (
            <ListItem
              onClick={() => {
                navigate(getQueryFromTags([tag]))
                setIsOpen(false)
              }}
              key={tag}
            >
              # {tag}
            </ListItem>
          ))}
        </ul>
      )}
    </div>
  )
}

export const BlogHeader: React.FC<{
  tags: string[]
  selectedTags: string[]
}> = ({ tags, selectedTags }) => {
  return (
    <header className="flex flex-col sm:flex-row justify-between">
      <h1 className="text-4xl sm:text-5xl text-left font-normal mb-4 sm:mb-12">
        {selectedTags.length > 0
          ? `# ${selectedTags[0]}`
          : "Thoughts and musings from the Livecycle team"}
      </h1>
      <TagsFilterMenu tags={tags} />
    </header>
  )
}
