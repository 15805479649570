import React from "react"
import { TagLink } from "./Tags"

export interface SummarizedAuthorProps {
  name: string
  authorimage: {
    childImageSharp: {
      original: {
        src: string
      }
    }
  }
}

export const SummarizedAuthor: React.FC<{
  author: SummarizedAuthorProps
}> = ({ author }) => {
  return (
    <div className="flex flex-row align-center">
      <div>
        <img
          src={author.authorimage.childImageSharp.original.src}
          alt={author.name}
          className="rounded-full border-1 border-black w-15 h-15"
        />
      </div>
      <p className="ml-3 text-base" style={{ alignSelf: "center" }}>
        {author.name}
      </p>
    </div>
  )
}
