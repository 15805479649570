import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { SummarizedAuthor } from "../components/blog/SummarizedAuthor"
import { PostMetadata } from "../components/blog/types"
import { Layout } from "../components"
import { BlogHeader } from "../components/blog/Header"
import Masonry from "react-masonry-css"
import { TagLink, Tags } from "../components/blog/Tags"

interface Data {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: PostMetadata
      }
    }[]
    group: { tag: string; totalCount: number }[]
  }
}

const ArticleInfo: React.FC<{ post: PostMetadata }> = ({ post }) => {
  return (
    <div className="flex flex-col space-y-3 items-start">
      <p className="text-lg">{post.date}</p>
      <a
        className="text-2xl font-normal hover:no-underline hover:text-lc-plum text-lc-black visited:text-lc-black"
        href={`/blogs/${post.slug}`}
      >
        {post.title}
      </a>
      <SummarizedAuthor author={post.author} />
      <Tags tags={post.tags} />
    </div>
  )
}

const BlogCard: React.FC<{ post: PostMetadata }> = ({ post }) => {
  return (
    <article className="flex flex-col items-start border-solid border-6 border-lc-black mb-9">
      <div className="border-solid border-b-4 border-lc-black">
        <a href={`/blogs/${post.slug}`}>
          <GatsbyImage
            imgStyle={{ width: "100%", maxHeight: "100%", height: "100%" }}
            objectFit="contain"
            image={post.featuredImage.childImageSharp.gatsbyImageData}
            alt={post.title}
          />
        </a>
      </div>
      <div className="p-5">
        <ArticleInfo post={post} />
      </div>
    </article>
  )
}

const MainBlogCard: React.FC<{ post: PostMetadata }> = ({ post }) => {
  return (
    <article className="flex flex-row items-start border-solid border-6 border-lc-black min-h-88">
      <div className="p-5 mt-auto" style={{ width: "100%" }}>
        <ArticleInfo post={post} />
      </div>
      <div className="border-solid border-l-6 border-lc-black h-full">
        <a href={`/blogs/${post.slug}`} className="h-full">
          <GatsbyImage
            objectFit="contain"
            image={post.featuredImage.childImageSharp.gatsbyImageData}
            alt={post.title}
          />
        </a>
      </div>
    </article>
  )
}

function getQueryVariable(query: string, variable: string): string[] {
  const values = []
  let vars = query.split("&")

  for (let v of vars) {
    let pair = v.split("=")

    if (decodeURIComponent(pair[0]) == variable) {
      values.push(decodeURIComponent(pair[1]))
    }
  }

  return values
}

const Blog: React.FC<PageProps<Data>> = ({ data, location }) => {
  const selectedTags = useMemo(
    () =>
      getQueryVariable(location.search.slice(1, location.search.length), "tag"),
    [location.search]
  )

  const filteredPosts = useMemo(
    () =>
      data.allMarkdownRemark.edges.filter(
        ({
          node: {
            frontmatter: { tags },
          },
        }) => {
          if (selectedTags.length === 0) {
            return true
          }

          let hasTag = false

          selectedTags?.forEach(tagFilter => {
            if (tags?.includes(tagFilter)) {
              hasTag = true
            }
          })

          return hasTag
        }
      ),
    [selectedTags]
  )

  const [featuredPost, ...restPosts] = filteredPosts
  const blogs = restPosts.map(n => (
    <BlogCard post={n.node.frontmatter} key={n.node.frontmatter.slug} />
  ))

  return (
    <Layout
      currentSection="blog"
      title="The Livecycle Blog"
      description={`The Livecycle Blog. Reflections and musings on ephemeral environments, developer experience, deployment previews, low code annotations, contextual collaboration and more. `}
    >
      <div className="p-6 max-w-6xl mx-auto">
        <BlogHeader
          tags={data.allMarkdownRemark.group.map(g => g.tag)}
          selectedTags={selectedTags}
        />

        {featuredPost && (
          <>
            <div className="hidden md:flex mb-14">
              <MainBlogCard
                post={featuredPost.node.frontmatter}
                key={featuredPost.node.frontmatter.slug}
              />
            </div>
            <div className="md:hidden mb-5">
              <BlogCard
                post={featuredPost.node.frontmatter}
                key={featuredPost.node.frontmatter.slug}
              />
            </div>
          </>
        )}
        <Masonry
          breakpointCols={{
            default: 3,
            1024: 2,
            640: 1,
          }}
          className="flex -ml-10"
          columnClassName="pl-10"
        >
          {blogs}
        </Masonry>
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            tags
            featuredImage {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.64)
              }
            }
            author {
              name
              authorimage {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
