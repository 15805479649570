import React from "react"
import { navigate } from "gatsby"

export const TagLink: React.FC<{ tag: string }> = ({ tag }) => {
  return <a onClick={() => navigate(`/blog?tag=${tag}`)}>{`#${tag}`}</a>
}

export const Tags: React.FC<{ tags: string[] }> = ({ tags }) => {
  return tags ? (
    <div className="grid grid-flow-col w-max gap-1">
      {tags.map(tag => (
        <TagLink key={tag} tag={tag} />
      ))}
    </div>
  ) : null
}
